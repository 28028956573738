$(function() {
    // flash auto hide
    $('#flash-msg .alert').not('.alert-danger, .alert-important').delay(6000).slideUp(500);

    // Add operation
    $('.row-operation').on('click', '.add-operation', function() {
        let wrapper = $(this).parents('.row-operations');

        let template = $(this).parents('.row-operation').clone(true, true);
        $(template).addClass('rounded-top pt-1 border-top rounded-end');
        let indexRow = $(wrapper).find('.row-operation').length + 1;
        $(template).find('.accordion-header, .accordion-collapse, .accordion-button, .image-area').each(function() {
            if ($(this).attr('id')) {
                $(this).attr('id', $(this).attr('id').replace(/(\d+)/, indexRow));
            }
            if ($(this).attr('aria-labelledby')) {
                $(this).attr('aria-labelledby', $(this).attr('aria-labelledby').replace(/(\d+)/, indexRow));
            }
            if ($(this).attr('data-bs-target')) {
                $(this).attr('data-bs-target', $(this).attr('data-bs-target').replace(/(\d+)/, indexRow));
            }
            if ($(this).attr('aria-controls')) {
                $(this).attr('aria-controls', $(this).attr('aria-controls').replace(/(\d+)/, indexRow));
            }
        });
        $(template).find('input, select, textarea, label').each(function() {
            if ($(this)[0].tagName === "SELECT") {
                $('option:first', this).attr('selected', 'selected');
            } else {
                $(this).val('');
            }
            if ($(this).attr('name')) {
                $(this).attr('name', $(this).attr('name').replace(/(\[\d+\])/, '[' + indexRow + ']'));
            }
            if ($(this).attr('id')) {
                $(this).attr('id', $(this).attr('id').replace(/(\[\d+\])/, '[' + indexRow + ']'));
            }
            if ($(this).attr('for')) {
                $(this).attr('for', $(this).attr('for').replace(/(\[\d+\])/, '[' + indexRow + ']'));
            }
        });
        $(template).find('.sort-input-operations').val(indexRow);

        $(wrapper).append(template); // Add field html
        // $(this).next().removeClass('d-none');
        $(wrapper).find('.remove-operation').removeClass('d-none');
        dragAndDrop('.row-operations', '.row-operation');
    });

    // Remove operation
    $('.row-operations').on('click', '.remove-operation', function(e) {
        e.preventDefault();
        // Remove row
        let wrapper = $(this).parents('.row-operations');
        $(this).parents('.row-operation').remove(); // Remove field html
        // Hide remove button on last row
        if ($(wrapper).find('.row-operation').length === 1) {
            $(wrapper).find('.remove-operation').addClass('d-none');
            $(wrapper).find('.accordion-header, .accordion-collapse, .accordion-button').each(function() {
                if ($(this).attr('id')) {
                    $(this).attr('id', $(this).attr('id').replace(/(\d+)/, 0));
                }
                if ($(this).attr('aria-labelledby')) {
                    $(this).attr('aria-labelledby', $(this).attr('aria-labelledby').replace(/(\d+)/, 0));
                }
                if ($(this).attr('data-bs-target')) {
                    $(this).attr('data-bs-target', $(this).attr('data-bs-target').replace(/(\d+)/, 0));
                }
                if ($(this).attr('aria-controls')) {
                    $(this).attr('aria-controls', $(this).attr('aria-controls').replace(/(\d+)/, 0));
                }
            });
            $(wrapper).find('input, select, textarea, label').each(function() {
                if ($(this).attr('name')) {
                    $(this).attr('name', $(this).attr('name').replace(/(\[\d+\])/, '[0]'));
                }
                if ($(this).attr('id')) {
                    $(this).attr('id', $(this).attr('id').replace(/(\[\d+\])/, '[0]'));
                }
                if ($(this).attr('for')) {
                    $(this).attr('for', $(this).attr('for').replace(/(\[\d+\])/, '[0]'));
                }
            });
        }
    });

    // Unset customized price
    $('#id').on('change', function() {
        let selectedOption = $(this).val();
        $('[name*=base_price_id] option').prop('disabled', false);
        $('[name*=base_price_id] option[value='+ selectedOption +']').prop('disabled', true);
    });
    $(window).on('load', function() {
        if ($('#id option:selected').length > 0 && !$('#id option:selected:contains("не выбрано")').length) {
            let selectedOption = $('#id').val();
            console.log(selectedOption)
            $('[name*=base_price_id] option[value=' + selectedOption + ']').prop('disabled', true);
        }
    });

    // Show input
    $('select[name*="[value]"]').on('change', function() {
        if($(this).val() === 'other') {
            $(this).next('[name*=value_other]').removeClass('d-none');
        } else {
            $(this).next('[name*=value_other]').addClass('d-none');
        }
    });

    $('.iv-variable').on('change', function() {
        isVariable($(this).val())
    });
    function isVariable(entity) {
        // Send progress
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        $.ajax({
            url: '/entities/isvariable',
            type: 'POST',
            dataType: 'json',
            data: {
                entity: entity,
            },
            success: function(data) {

                if (data == 1) {
                    console.log("GODD");
                } else {
                    console.log("NOT GODD");
                }
            }
        });
    }

    $('.update-products').on('click', function() {
        updateProducts($(this).data('command'));
        $(this).prop('disabled', true).attr('disabled', 'disabled');
        $(this).find('span').css('visibility', 'visible');
        intervalDeal($(this).data('command'), $(this).data('message'));
    });

    $('.set-attribute').on('click', function () {
        let input  = $(this).parents('.input-group').find('input'),
            oldVal = input.val();
        input.val(parseFloat($(this).text()));
        $(this).text(parseFloat(oldVal));
    });
    $('.reset-attribute').on('click', function () {
        $(this).parents('.input-group').find('input').val(parseFloat($(this).data('default')));
        $(this).parents('.input-group').find('.set-attribute').text(parseFloat($(this).data('diff')));
    });

    function updateProducts(command) {
        // Send progress
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        $.ajax({
            url: '/settings/updateall',
            type: 'POST',
            contentType: 'application/json; charset=utf-8',
            dataType: 'json',
            data: JSON.stringify({
                command: command,
            }),
            success: function(data) {

                if (data == 1) {
                    console.log("GODD");
                } else {
                    console.log("NOT GODD");
                }
            }
        });
    }

    function statusCommand(command, callback) {
        // Send progress
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        return $.ajax({
            url: '/settings/status',
            type: 'POST',
            contentType: 'application/json; charset=utf-8',
            dataType: 'json',
            data:JSON.stringify({
                command: command,
            }),
        })
            .done(callback)
            .fail(function(jqXHR, textStatus, errorThrown) {
                triggerAlert('Возникла непредвиденная ошибка перезагрузите страницу.', 'danger');
            });
    }

    function intervalDeal(command, message) {
        let delay = 10000;
        let timerId = setTimeout(function request() {
            let res = statusCommand(command, function(data) {
                if (data) {
                    $('[data-command="' + command + '"]').prop('disabled', false).removeAttr('disabled');
                    $('[data-command="' + command + '"]').find('span').css('visibility', 'hidden');
                    triggerAlert(message, 'success');
                    clearTimeout(timerId);
                } else {
                    timerId = setTimeout(request, delay);
                }
            });
        }, delay);
    }

    window.onload = function() {
        $('.update-products').each(function() {
            if ($(this).hasClass('work')) {
                intervalDeal($(this).data('command'), $(this).data('message'));
            }
        });
    }

    // Проектная цена товара
    $('.prepare-prices').on('click', function() {
        let button = $(this);
        button.prop('disabled', true).addClass('disabled');
        button.find('span').removeClass('d-none');

        let form = $('#product-attributes');
        // Send progress
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        $.ajax({
            url: form.attr('action'),
            type: form.attr('method'),
            contentType: 'application/json; charset=utf-8',
            dataType: 'json',
            data: JSON.stringify(form.serializeArray()),
        })
            .done(function(data) {
                if (data && Object.keys(data).length > 0) {
                    $.each(data, function(index, value) {
                        if (value.price > 0) {
                            $('.project-prices input[name="' + index + '"]').val(value.price / 100)
                        }
                    });
                    button.prop('disabled', false).removeClass('disabled');
                    button.find('span').addClass('d-none');
                } else {
                    triggerAlert('Возникла непредвиденная ошибка перезагрузите страницу.', 'danger');
                }
            })
            .fail(function(jqXHR, textStatus, errorThrown) {
                triggerAlert('Возникла непредвиденная ошибка перезагрузите страницу.', 'danger');
            });
    });
});

// Перетягивание элементов https://htmlacademy.ru/blog/articles/drag-and-drop
function dragAndDrop(parentSelector, rowSelector) {
    const tasksListElement = document.querySelectorAll(parentSelector);

    for (const tasks of tasksListElement) {
        const taskElements = tasks.querySelectorAll(rowSelector);

        for (const task of taskElements) {
            task.draggable = true;
        }

        tasks.addEventListener(`dragstart`, (evt) => {
            evt.target.classList.add(`selected`);
        });

        tasks.addEventListener(`dragend`, (evt) => {
            evt.target.classList.remove(`selected`);
            let sortInputQuestions = evt.target.parentNode.querySelectorAll('.sort-input-operations');
            for (let i = 0; i < sortInputQuestions.length; i++) {
                sortInputQuestions[i].value = i + 1;
            }
        });

        const getNextElement = (cursorPosition, currentElement) => {
            const currentElementCoord = currentElement.getBoundingClientRect();
            const currentElementCenter = currentElementCoord.y + currentElementCoord.height / 2;

            const nextElement = (cursorPosition < currentElementCenter) ?
                currentElement :
                currentElement.nextElementSibling;

            return nextElement;
        };

        tasks.addEventListener(`dragover`, (evt) => {
            evt.preventDefault();

            const activeElement = tasks.querySelector(`.selected`);
            const currentElement = evt.target;
            const isMoveable = activeElement !== currentElement &&
                currentElement.classList.contains(rowSelector.replace('.', ''));

            if (!isMoveable) {
                return;
            }

            const nextElement = getNextElement(evt.clientY, currentElement);

            if (
                nextElement &&
                activeElement === nextElement.previousElementSibling ||
                activeElement === nextElement
            ) {
                return;
            }

            tasks.insertBefore(activeElement, nextElement);
        });
    }
}

// Reset filter form and send
window.resetForm = function(element, event) {
    event.preventDefault();
    element.disabled = true;
    let form = element.closest('form');
    form.querySelectorAll('input, select').forEach(el=>el.value = '');
    form.submit();
}

// TODO ????
window.checkForm = function(form) {
    let inputs = form.querySelectorAll('input, select'),
        submit = form.querySelector('[type="submit"]');
    if (checkInputs(inputs)) {
        submit.classList.remove('disabled');
        submit.disabled = false;
    } else {
        submit.classList.add('disabled');
        submit.disabled = true;
    }
}

// Check form inputs if it has not empty
function checkInputs(inputs) {
    let result = false;
    for (let i = 0; i < inputs.length; i++) {
        if (inputs[i].value != '') {
            result = true;
        }
    }
    return result;
}

function triggerAlert(message, type) {
    const alertPlaceholder = document.getElementById('flash-msg')
    const alert = (message, type) => {
        const wrapper = document.createElement('div')
        wrapper.innerHTML = [
            `<div class="alert alert-${type} alert-dismissible" role="alert">`,
            `   <div>${message}</div>`,
            '   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Закрыть"></button>',
            '</div>'
        ].join('')

        alertPlaceholder.append(wrapper)
    }
    alert(message, type);
}
